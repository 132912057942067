// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const my = {
  game: `ဂိမ်း`,
  games: `ဂိမ်းများ`,
  center: `စင်တာ`,
  game_history: `ဂိမ်းသမိုင်း`,
  coupon_code: `ကူပွန်ကုဒ်`,
  telegram: `ကြေးနန်းစာ`,
  customer_service: `ဧည့်ဝန်ဆောင်မှု`,
  player: `ဘာညာ`,
  winner: `အောင်နိုင်သူ`,
  all_bets: `အလောင်းအစားအားလုံး`,
  my_bets: `ကျွန်ုပ်၏အလောင်းအစားများ`,
  name: `နာမည်`,
  time: `အချိန်`,
  bet_amount: `လောင်းကြေးပမာဏ`,
  prediction: `အဟော`,
  payout: `ပေးချေခြင်း။`,
  login: `လော့ဂ်အင်`,
  register: `မှတ်ပုံတင်ပါ။`,
  username_placeholder: `အသုံးပြုသူအမည်`,
  please_input_your_x: `သင်၏ {{x}} ကို ထည့်သွင်းပါ`,
  captcha: `CAPTCHA`,
  forgot_password: `စကားဝှက်မေ့နေပါသလား`,
  minor_note: `ဤဆိုက်ကို ဝင်ကြည့်ရန် သင်သည် အသက် 18 နှစ်ကျော်ပြီး သဘောတူကြောင်း သေချာပါစေ။`,
  terms_of_service: `ဝန်ဆောင်မှုစည်းမျဉ်းများ`,
  dont_have_account: `အကောင့်မရှိဘူးလား။`,
  sign_up: `ဆိုင်းအပ်`,
  username: `အသုံးပြုသူအမည်`,
  email: `အီးမေးလ်`,
  full_name: `နာမည်အပြည့်အစုံ`,
  confirm_18: `ကျွန်ုပ်သည် အသက် 18 နှစ်ရှိပြီဖြစ်ပြီး ဖတ်ပြီးကြောင်း အတည်ပြုပါသည်။`,
  already_have_account: `အကောင့်ရှိပြီးသားလား?`,
  share: `မျှဝေပါ။`,
  deposit: `အပ်ငွေ`,
  bonus: `ဘောနပ်စ်`,
  app: `အက်ပ်`,
  load_more: `နောက်ထပ် တင်ပါ။`,
  details: `အသေးစိတ်`,
  amount: `ပမာဏ`,
  extra: `အပို`,
  deposit_bonus: `အပ်ငွေအပိုဆု`,
  do_not_participate: `ပရိုမိုးရှင်းတွေမှာ မပါဝင်ပါနဲ့။`,
  payment_problem: `ငွေပေးချေမှု ပြဿနာ`,
  available_balance: `လက်ကျန်ငွေ ရရှိနိုင်`,
  withdraw: `ငွေထုတ်ပါ။`,
  min: `မင်း`,
  max: `မက်တယ်။`,
  notifications: `အသိပေးချက်များ`,
  invalid_x: `မမှန်ကန်ပါ {{x}}`,
  captcha_not_match: `Captcha သည် မကိုက်ညီပါ။`,
  invalid_x_must_be_atleast_y_characters: `မမှန်ကန်သော {{x}}၊ အနည်းဆုံး စာလုံး {{y}} ဖြစ်ရမည်။`,
  x_is_required: `{{x}} လိုအပ်သည်။`,
  phone_number: 'ဖုန်းနံပါတ်',
  phone_num_x_max: `ဖုန်းနံပါတ် အများဆုံး {{x}} ဂဏန်းများ`,
  email_sent: `အီးမေးလ်ပို့ပြီး၊ သင့်အီးမေးလ်ကို စစ်ဆေးပါ။`,
  enter_valid_email: `Please enter a valid email.`,
  confirm: `အတည်ပြုပါ။`,
  password_atleast_6_characters: `စကားဝှက်သည် အနည်းဆုံး အက္ခရာ 6 လုံးရှိရမည်။`,
  password_reset_success: `စကားဝှက်ပြန်လည်သတ်မှတ်ခြင်း အောင်မြင်ပါသည်။`,
  new_password: `စကားဝှက်အသစ်`,
  bank: 'ဘဏ်',
  bank_transfer: `ဘဏ်ငွေလွှဲခြင်း။`,
  bank_name: `ဘဏ်အမည်`,
  account_name: `အကောင့်အမည်`,
  account_number: `အကောင့်နံပါတ်`,
  drop_or_select: `ဖိုင်ကိုချပါ သို့မဟုတ် ရွေးပါ။`,
  drop_files_here: `ဖိုင်များကို ဤနေရာတွင် ချလိုက်ပါ။`,
  transfer_to: `သို့ လွှဲပြောင်းပါ။`,
  upload_receipt: `ပြေစာတင်ပါ။`,
  select_bonus: `Bonus ကိုရွေးချယ်ပါ။`,
  no_promotion: `Promotion မရှိပါ။`,
  please_upload_receipt: `ပြေစာ အပ်လုဒ်လုပ်ပါ။`,
  bank_account: `ဘဏ်အကောင့်`,
  add_account: `အကောင့်ထည့်ပါ။`,
  account_holder_name: `အကောင့်ကိုင်ဆောင်သူအမည်`,
  name_of_the_account_holder: `အကောင့်ကိုင်ဆောင်သူအမည်`,
  fill_in_bank_account: `ဘဏ်အကောင့်ဖြည့်ပါ။`,
  withdraw_information: `အချက်အလက်များ ထုတ်ယူပါ။`,
  note: `မှတ်ချက်`,
  amount_withdrawn: `ထုတ်ယူသည့်ပမာဏ`,
  amount_no_exceed_balance: `ထုတ်ယူသည့်ပမာဏသည် ရရှိနိုင်သောလက်ကျန်ပမာဏထက် မကျော်လွန်သင့်ပါ။`,
  minimum_withdraw_amount_is_x: `အနည်းဆုံး ငွေထုတ်ပမာဏမှာ {{x}} ဖြစ်သည်`,
  maximum_withdraw_amount_is_x: `အများဆုံးထုတ်ယူသည့်ပမာဏမှာ {{x}} ဖြစ်သည်`,
  transaction_history: `ငွေလွှဲမှတ်တမ်း`,
  date_from: `နေ့စွဲမှ`,
  date_to: `နေ့စွဲသို့`,
  account_name_must_be_4: `အကောင့်အမည်သည် အရှည် 4 ထက်ကြီးရပါမည်။`,
  account_number_must_be_x: `အကောင့်နံပါတ်သည် အရှည် 10-14 ဖြစ်ရပါမည်။`,
  minimum_length_is_x: `အနိမ့်ဆုံးအရှည်မှာ {{x}}`,
  maximum_length_is_x: `အများဆုံးအရှည်မှာ {{x}}`,
  payment_method: `ငွေပေးချေစနစ်`,
  mode: `ပုံစံ`,
  no_bank_account: `သင့်တွင် ထုတ်ယူရန် မည်သည့်အကောင့်မှ မရှိသေးပါ၊ ယခုထည့်ပါ။`,
  please_select_bank: `ဘဏ်အကောင့်တစ်ခုကို ရွေးပါ။`,

  bet_history: `အလောင်းအစားသမိုင်း`,
  game_provider: `ဂိမ်းပံ့ပိုးသူ`,
  bet_count: `လောင်းကြေးအရေအတွက်`,
  win_loss: `နိုင်/အရှုံး`,
  rebate: `ပြန်အမ်းငွေ`,
  turnover: `လည်ပတ်မှု`,
  game_category: `ဂိမ်းအမျိုးအစား`,

  you_have_x_unread_messages: `သင့်တွင် မဖတ်ရသေးသော စာတို {{x}} စောင်ရှိသည်။`,
  deposit_amount_between_x_and_y: `အပ်ငွေပမာဏသည် {{x}} နှင့် {{y}} ကြားရှိရမည်`,
  minimum_amount_is_x_y: `အနည်းဆုံးပမာဏမှာ {{x}}{{y}}`,
  from_selected_promotion: 'ရွေးချယ်ထားသော ပရိုမိုးရှင်းမှ',
  from_selected_bank: 'ရွေးချယ်ထားသော ဘဏ်မှ',
  deposit_from_bank: 'ဘဏ်မှ ထည့်ငွေ',
  payment_draft: 'သင်ပေးချေပြီးကြောင်း အတည်ပြုပါက ပေးချေမှုကို ဆက်လက် လုပ်ဆောင်နေသေးသောကြောင့် ခေတ္တစောင့်ပါ',

  // all
  all: `အားလုံး`,
  title: `ခေါင်းစဥ်`,
  date: `ရက်စွဲ`,
  type: `ရိုက်ပါ။`,
  status: `အဆင့်အတန်း`,
  remark: `မှတ်ချက်`,
  category: `အမျိုးအစား`,
  please_select_x: `ကျေးဇူးပြု၍ {{x}} ကို ရွေးပါ`,
  save: `သိမ်းဆည်းပါ။`,

  change_password: `စကားဝှက်ကိုပြောင်းရန်`,
  current_password: `လက်ရှိစကားဝှက်`,
  confirm_password: `စကားဝှက်အသစ်ကို အတည်ပြုပါ။`,
  submit: `တင်ပြပါ။`,
  password_changed: `စကားဝှက်ပြောင်းထားသည်။`,
  x_must_be_y_characters: `{{x}} သည် အနည်းဆုံး အက္ခရာ {{y}} လုံး ဖြစ်ရမည်။`,
  reset_password: `လျှို့ဝှတ်နံပါတ်အားမူလအတိုင်းပြန်လုပ်သည်`,
  casino: `ကာစီနို`,
  slots: `စလော့`,
  live_casino: `တိုက်ရိုက်ကာစီနို`,
  fishing: `အင်အားရှယ်ပြီး`,
  sports: `အားကစား`,
  cards: `ကဒ်`,
  clear: `ရှင်းလင်းသော`,
  search: `ရှာရန်`,
  new: `အသစ်`,
  logout: `ထွက်လိုက်ပါ။`,
  choose_date: `ရက်စွဲကို ရွေးပါ။`,
  select_both_date: `Date From နှင့် To နှစ်ခုလုံးကို ရွေးပါ။`,
  from: `ထံမှ`,
  to: `ရန်`,
  apply: `လျှောက်ထားပါ။`,
  promotions: `ဈေးရောင်း`,
  learn_more: `ပိုမိုသိရှိရန်`,
  password_doesnt_match: `စကားဝှက်နှင့် မကိုက်ညီပါ။`,
  cancel: `မလုပ်တော့`,
  confirm_to_pay: `ပေးချေရန် အတည်ပြုပါ။`,
  confirm_to_withdraw: `ရုပ်သိမ်းရန်အတည်ပြုပါ။`,
  deposit_amount: `အပ်ငွေပမာဏ`,
  loading_please_wait: `တင်နေသည်၊ ခဏစောင့်ပါ။`,
  account: `အကောင့်`,
  profile: `ကိုယ်ရေးအကျဉ်း`,
  bank_list: `ဘဏ်စာရင်း`,
  in_maintenance: `ထိန်းသိမ်းခြင်း။`,
  birthday: `မွေးနေ့`,
  birthday_bonus: `အပိုဆုတစ်ခုအတွက် သင့်မွေးနေ့ကို ထည့်ပါ။ 🎉🎁`,
  referral_code: `ရည်ညွှန်းကုဒ်`,
  referral: `ရည်ညွှန်းသည်။`,
  my_referral: `ကျွန်ုပ်၏ ရည်ညွှန်းချက်`,
  my_claim: `ကျွန်ုပ်၏ တောင်းဆိုချက်`,
  claim_profit: `အမြတ်ငွေကို တောင်းဆိုပါ။`,
  available_profit: `ရရှိနိုင်သောအမြတ်`,
  claimed_profit: `တောင်းဆိုထားသောအမြတ်`,
  all_time_profit: `အချိန်တိုင်းအမြတ်`,
  referral_note_on_x: `သင့်သူငယ်ချင်းများ {{x}} တွင် ကစားသည့်အခါတိုင်း အမြတ်ရယူပါ`,
  tier: `အဆင့်`,
  commission: `ကော်မရှင်`,
  referred_member: `ရည်ညွှန်းအဖွဲ့ဝင်`,
  profit_earned: `အမြတ်များ`,
  account_information: `အကောင့်အချက်အလက်`,
  invite_friends: `သူငယ်ချင်းများကိုဖိတ်ကြားသည်`,
  how_it_work: `ဘယ်လိုအလုပ်လုပ်လဲ?`,
  invite_note: `သင့်သူငယ်ချင်းသည် သင့်ရည်ညွှန်းကုဒ်ကို အသုံးပြု၍ အကောင့်ဖွင့်သည့်အခါ သီးသန့် ညွှန်းဆိုခွင့်အပိုဆုများ ရယူလိုက်ပါ။`,
  share_via: `တဆင့်မျှဝေပါ။`,
  referral_link: `ရည်ညွှန်းလင့်ခ်`,
  total_profit: `စုစုပေါင်းအမြတ်`,
  copied: `ကူးယူသည်။`,
  nothing_to_claim: `ဘာမှ တောင်းဆိုစရာ မရှိပါဘူး။`,
  claim_history: `တိုင်ကြားမှုမှတ်တမ်း`,
  downline: `ငါ့ဒေါင်းလိုင်း`,
  last_x_digit_bank_ref_number: `Bank Ref နံပါတ်၏ နောက်ဆုံးဂဏန်း {{x}}`,
  bank_ref_number_validation_x: `Bank Ref Number လိုအပ်ပြီး အက္ခရာအရှည် {{x}} ဖြစ်ရပါမည်။`,
  sort: 'အမျိုးအစား',
  copy_your_referral: 'သင့်ရိုးချပုံအကြောင့်ကိုကြော်ငြင်ဆိုနေသည်နှင့်နိုဝင်မှသားမျှဝေရရှိရန်မွှေချချွအသုံးပြုနိုင်ပါ။',
  how_to_get_referral_bonus: 'သူငယ်ချင်းမိတ်ဆက်ဘောနပ်စ် ဘယ်လိုရအောင်ယူမလဲ?',
  steps_to_claim: 'ဘောနပ်စ်ရယူပုံအဆင့်ဆင့်',
  share_your_referral_code:
    '၁.သင့်သူငယ်ချင်းကို Referral code ကိုသူငယ်ချင်းအား အကောင့်ဖွင့်နိုင်ရန်အတွက် viber, messenger,telegram.facebook.twitter,or instagram.ကနေတဆင့်မျှဝေပေးရန်',
  after_friend_register:
    '၂.သူငယ်ချင်းက zk7.မှာအကောင့်ဖွင့်ပြီးပြီဆိုရင် မိတ်ဆက်ဘောနပ်စ်ရယူရန်အတွက် နှစ်ဉီးလုံးက ငွေသွင်းကစားရန်လိုအပ်ပါတယ်',
  refer_a_friend_bonus: '၃.သူငယ်ချင်းမိတ်ဆက်ဘောနပ်စ် တွက်ချက်နည်း',
  turnover_played: 'ကစားသည့်ထိုးကြေးစုစုပေါင်းxကော်မရှင်%=သူငယ်ချင်းမိတ်ဆက်ဘောနပ်စ်',
  example: 'ဉပမာ',
  turnover_formula_x: 'ထိုးကြေး (၁,၀၀၀,၀၀၀)x၀.၀၀၂% (ကော်မရှင် %)= ၂၀၀၀ {{x}}',
  note_bonus_only: 'မှတ်ချက်*ဘောနပ်စ်ကို နောက်တနေ့ ၁၂ နာရီ နောက်ပိုင်းသာ ရယူနိုင်သည်',
  note_bonus_only_x: 'မှတ်ချက်*ဘောနပ်စ်ကို နောက်တနေ့ {{x}} နောက်ပိုင်းသာ ရယူနိုင်သည်',
  access_dashboard:
    '၄. အမြတ်ငွေနဲ့ ကော်မရှင်ကိုကြည့်ရှု့ရန် သူငယ်ချင်းမိတ်ဆက် စာမျက်နှာတွင် ဝင်ရောက်ကြည့်ရှု့နိုင်ပါသည်',
  click_this_button_to_claim: 'အမြတ်ငွေတောင်းဆိုပါ ဆိုတဲ့စာသားကိုနှိပ်ပြီး ဘောနပ်စ်ကို ရယူနိုင်ပါသည်',
  commision_tier: 'ကော်မရှင်အဆင့်',
  member_downline: 'ကိုယ့်ရဲ့ မိတ်ဆက်ပေးသူများ',
  bonus_tutorial_video: 'ဘောနပ်စ်ရယူပုံအဆင့်ဆင့် လက်တွေ့ပြပုံ',
  popularity: 'နာမည်တော်',
  no_balance: 'သင့်အတွက် လက်ကျန်ငွေများကိုလက်ခံရန်အသိအမှတ်များ မရှိပါ',
  default: 'ပုံသေ',
  claim_bonus: 'တောင်းဆိုမှု',
  claimed: 'တိုင်ကြားထားသည်။',
  bonus_claimed: 'အပိုဆုများကို တောင်းဆိုထားသည်။',
  connect_with_us: `ကျွန်ုပ်တို့နှင့်ချိတ်ဆက်ပါ။`,
  level: `အဆင့်`,
  online_banking: 'အွန်လိုင်းဘဏ်လုပ်ငန်း',
  brands: 'Brands',
  no_result: 'ရလဒ်မရှိပါ။',
};

export default my;
